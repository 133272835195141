import NcImage from "shared/NcImage/NcImage";

export default function CardNFT(id) { 

  return (
    <div
      className="nc-CardNFT relative flex flex-col group !border-0 [ nc-box-has-hover nc-dark-box-bg-has-hover ]"
      data-nc-id="CardNFT"
    >
      <div className="relative flex-shrink-0 ">
        <div>
        <a href={'/nft-detail?type=way&id=' + id.toString()}>
          <NcImage
            containerClassName="flex aspect-w-11 aspect-h-12 w-full h-0 rounded-3xl overflow-hidden will-change-transform"
            src="./images/land-default.png"
            className="object-cover w-full h-full group-hover:scale-[1.03] transition-transform duration-300 ease-in-out rounded-3xl will-change-transform"
          />
          </a>
        </div>
        <div className="absolute top-3 inset-x-3 flex"></div>
      </div>

      <div className="p-4 py-5 space-y-3">
        <h2 className={`text-lg font-medium`}>
          OSM # {id.toString()}
        </h2>

        <div className="w-2d4 w-full border-b border-neutral-100 dark:border-neutral-700"></div>

      </div>

      <a href={'/nft-detail?type=way&id=' + id.toString()} className="absolute inset-0">More details</a>
    </div>
  );
};
