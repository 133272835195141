import React from "react";

export interface ItemTypeVideoIconProps {
  className?: string;
}

const ItemTypeVideoIcon: React.FC<ItemTypeVideoIconProps> = ({
  className = "w-8 h-8 md:w-10 md:h-10",
}) => {
  return (
    <span></span>
  );
};

export default ItemTypeVideoIcon;
