import rightImg from "images/about-hero-right.png";
import React, { FC } from "react";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionHero from "./SectionHero";

export interface PagePageMapProps {
  className?: string;
}

const PageMap: FC<PagePageMapProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageMap overflow-hidden relative ${className}`}
      data-nc-id="PageMap"
    >
      <Helmet>
        <title>Map of DL Mints - Digital Land NFT Marketplace</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container py-8 lg:py-8 space-y-8 lg:space-y-8">
        <SectionHero
          rightImg={rightImg}
          heading="🗺️ Map."
          btnText=""
          subHeading="Where is the Digital Land?"
        />
      <div className="container py-8 lg:py-8 space-y-8 lg:space-y-8">
      <div className="relative py-8">
        <h1><b>Maps</b></h1>
        <br/><a target="_blank" style={{color:"blue"}} rel="noreferrer" href="https://digital-land-map.neocities.org">1. Map of DL Mints</a>
        <br/><a target="_blank" style={{color:"blue"}} rel="noreferrer" href="https://app.sigle.io/friedger.id/w80WXRpdw5tP835_RK0Ip">2. Creating a Bitfari Map</a>
        <br/><a target="_blank" style={{color:"blue"}} rel="noreferrer" href="https://codeberg.org/friedger/bitfari-digital-land-map">3. Git Repo of Maps</a>
        <br/><a target="_blank" style={{color:"blue"}} rel="noreferrer" href="https://wiki.openstreetmap.org/wiki/Map_features">4. OSM Map Features</a>
        <br/><a target="_blank" style={{color:"blue"}} rel="noreferrer" href="https://www.openstreetmap.org/about">5. About OSM Data</a>

        <br/><br/>
</div>
        <SectionSubscribe2 />
      </div>
    </div>
    </div>
  );
};

export default PageMap;