import axios from 'axios';
import { Buffer } from "@stacks/common";
import React,  { useState, useEffect, Fragment } from "react";
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import { useConnect } from "@stacks/connect-react";
import WalletIcon from "components/WalletIcon";
import { userSession } from 'auth.js';
import { StacksMainnet } from '@stacks/network';
import {  NonFungibleConditionCode, createAssetInfo, standardPrincipalCV, stringAsciiCV
        , callReadOnlyFunction, uintCV
        , makeContractNonFungiblePostCondition, makeStandardNonFungiblePostCondition
        , bufferCVFromString, AnchorMode, cvToJSON } from "@stacks/transactions";

import { CONTRACT_ADDRESS, DL_NFT_ROOT }  from 'Constants';
 
export default function Burn() {

global.Buffer = global.Buffer || Buffer;
const { doContractCall } = useConnect();

let search = window.location.search;
let params = new URLSearchParams(search);
let token_id = params.get('tid') ;
 
let landlord =' ';
 
const network = new StacksMainnet();
 
// get the landlord STX address
if (userSession.isUserSignedIn())
{ 
  landlord = userSession.loadUserData()?.profile?.stxAddress?.mainnet
}

  const burn = async () => {
    //contract details
    const contractAddress = CONTRACT_ADDRESS;
    const contractToCall = 'b10';
    const functionToCall = 'burn-all';

    // clarity values for minting 
    const args = [ uintCV(token_id) ];

    // Create an NFT Post-condition
    // With a standard principal
    const postConditionAddress = landlord;
    const postConditionCode = NonFungibleConditionCode.DoesNotOwn;
    const assetAddress = CONTRACT_ADDRESS;
    const assetContractName = 'web4';
    const assetName = 'digital-land';
    const tokenAssetName = uintCV(token_id);
    const nonFungibleAssetInfo = createAssetInfo(assetAddress, assetContractName, assetName);

    const standardNonFungiblePostCondition = makeStandardNonFungiblePostCondition(
      postConditionAddress,
      postConditionCode,
      nonFungibleAssetInfo,
      tokenAssetName
    );
 
      let postConditions: any[] = [standardNonFungiblePostCondition];
      const postConditionMode = 0x02;

      await doContractCall({
        contractAddress,
        stxAddress: landlord,
        contractName: contractToCall,
        functionName: functionToCall,
        functionArgs: args,
        network,
        postConditions,
        postConditionMode,
        onFinish: data => {
          console.log('Token burn tx submitted - check your explorer', data);
        },
        onCancel: () => {
          window.location.href = '#';
        },
        anchorMode: AnchorMode.Any,
      });
    };


  return (
    <>   

    {(landlord ===' ') ? (
      <div className="mt-8 flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-3">
          <div className="flex-1">
           <span className="mt-4">Please connect your wallet to complete this operation!
           Once you log-in, you will be able to update your tokens. 
           </span>           
          </div>
          </div>
        
    ) : ( <Fragment>
   <center>
   <h2 className="inline-flex items-center text-1xl sm:text-2xl lg:text-2xl font-semibold">
            <div className="mt-4"> 
              <div>
              <br/>
              <small>** Token Burn Operation. This cannot be reversed, proceed with caution! ** </small><br/>
              <div className="mt-8 flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-3">
                <div className="flex-1">
                <ButtonPrimary onClick={ burn }>
                <WalletIcon/>
                <span className="ml-2.5">Burn Token</span>
                </ButtonPrimary>
              </div>
              </div>
              </div>
        </div>        
      </h2>  
      <br/><br/>
      </center>

      </Fragment>
    
    ) }

     </>
  );
};