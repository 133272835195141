import React, { FC, Fragment } from "react";
import { Link } from "react-router-dom";
import NextPrev from "shared/NextPrev/NextPrev";
import { Transition } from "@headlessui/react";
import NcImage from "shared/NcImage/NcImage";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ItemTypeVideoIcon from "components/ItemTypeVideoIcon";
import { nftsLargeImgs, nftsLargeLinks } from "contains/uiData";
import VerifyIcon from "components/VerifyIcon";

export interface CardLarge1Props {
  className?: string;
  onClickNext?: () => void;
  onClickPrev?: () => void;
  isShowing?: boolean;
  featuredImgUrl?: string;
  landNftUrl?: string;
}

let i = 1

const CardLarge1: FC<CardLarge1Props> = ({
  className = "",
  onClickNext = () => {},
  onClickPrev = () => {},
  isShowing = true,
  featuredImgUrl = nftsLargeImgs[i],
  landNftUrl = nftsLargeLinks[i],
}) => {

  return (  
    <div>
      <Transition
        as="div"
        className={`nc-CardLarge1 relative flex flex-col-reverse lg:flex-row justify-end ${className}`}
        show={isShowing}
      >
        <div className="lg:absolute z-10 lg:left-0 lg:top-1/2 lg:transform lg:-translate-y-1/2 -mt-2 lg:mt-0 sm:px-5 lg:px-0 w-full lg:max-w-lg ">
          <Transition.Child
            as={Fragment}
            enter="transform duration-500 delay-200 will-change-transform"
            enterFrom="translate-y-4 opacity-0"
            enterTo="translate-y-0 opacity-100"
          >
            <div className="p-4 sm:p-8 xl:py-14 md:px-10 bg-white dark:bg-neutral-900 shadow-lg rounded-3xl space-y-3 sm:space-y-8 ">
              {/* TITLE */}
              <h2 className="text-2xl lg:text-3xl 2xl:text-5xl font-semibold ">
                <Link to={"/collection"} title="Discover Digital Land Collections">
                Now Selling Global Icons!
                </Link>
              </h2>

              {/* COLLECTION */}
              <div className="flex flex-col sm:flex-row space-y-3 sm:space-y-0 sm:space-x-12">
                <div className="flex items-center">
                  <div className="flex-shrink-0 h-10 w-10">
                     
                  </div>
                  <div className="ml-3">
                    <div className="text-xs dark:text-neutral-400">Creator</div>
                    <div className="text-sm font-semibold flex items-center">
                      <span>Bitfari DAO</span>
                      <VerifyIcon />
                    </div>
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="flex-shrink-0 h-10 w-10">
                     
                  </div>
                  <div className="ml-3">
                    <div className="text-xs dark:text-neutral-400">
                      Collection
                    </div>
                    <div className="text-sm font-semibold flex items-center">
                      <span>Premium Collections</span><VerifyIcon />
                      </div>
                  </div>
                </div>
              </div>

              {/* PRICE */}
              <div className="pt-6">
                <div className="flex flex-col sm:flex-row items-baseline p-6 border-2 border-green-500 rounded-xl relative">
                  <span className="text-3xl xl:text-4xl font-semibold text-green-500">
                    2436 STX
                  </span>
                  <span className="text-lg text-neutral-400 sm:ml-3.5">
                    (≈ $999.99)
                  </span>
                </div>
              </div>

              <div className="w h-[1px] bg-neutral-100 dark:bg-neutral-700"></div>

              {/* DESCRIPTION */}
              <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-3">
                <ButtonSecondary href={landNftUrl} className="flex-1">
                  View item
                </ButtonSecondary>
              </div>
            </div>
          </Transition.Child>
          <Transition.Child
            as="div"
            className="p-4 sm:pt-8 sm:px-10 will-change-transform"
            enter="transform duration-500 delay-300"
            enterFrom="translate-y-4 opacity-0"
            enterTo="translate-y-0 opacity-100"
          >
            <NextPrev
              btnClassName="w-11 h-11 text-xl"
              onClickNext={onClickNext}
              onClickPrev={onClickPrev}
            />
          </Transition.Child>
        </div>
        <Transition.Child
          as="div"
          className="w-full lg:w-[64%] relative will-change-transform"
          enter="transform duration-300"
          enterFrom="translate-y-4 scale-105 opacity-0"
          enterTo="translate-y-0 scale-100 opacity-100"
        >
          <div>
            <Link to={landNftUrl}>
              <NcImage
                containerClassName="aspect-w-1 aspect-h-1 relative"
                className="absolute inset-0 object-cover rounded-3xl sm:rounded-[40px] border-4 sm:border-[14px] border-white dark:border-neutral-800"
                src={featuredImgUrl}
                alt={"title"}
              />
            </Link>

            {/* META TYPE */}
            <ItemTypeVideoIcon className="absolute w-8 h-8 md:w-10 md:h-10 left-3 bottom-3 sm:left-7 sm:bottom-7 " />
          </div>
        </Transition.Child>
      </Transition>
    </div>
  );
};

export default CardLarge1;
