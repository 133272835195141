import Logo from "shared/Logo/Logo";
import SocialsList1 from "shared/SocialsList1/SocialsList1";
import { CustomLink } from "data/types";
import React from "react";

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

const widgetMenus: WidgetFooterMenu[] = [
  {
    id: "5",
    title: "Getting started",
    menus: [
      { href: "https://www.bitfari.org/marketers/", label: "Marketers" },
      { href: "https://www.bitfari.org/2021/05/26/network-incentives/", label: "Network Incentives" },
      { href: "http://bitfari.xyz", label: "Ecosystem Directory" },
    ],
  },
  {
    id: "1",
    title: "Explore",
    menus: [
      { href: "https://www.bitfari.org/pricing/", label: "Pricing" },
      { href: "https://www.bitfari.org/press/", label: "Customers" },
      { href: "https://www.bitfari.org/2021/07/30/bitfari-domain-portfolio/", label: "Networks" },
    ],
  },
  {
    id: "2",
    title: "Resources",
    menus: [
      { href: "https://www.bitfari.org/docs/how-to-get-help/", label: "Support" },
      { href: "https://www.bitfari.org/developers/", label: "Developers" },
      { href: "https://www.bitfari.org/bitfari-roadmap/", label: "Roadmap" },
    ],
  },
  {
    id: "4",
    title: "Community",
    menus: [
      { href: "https://t.co/sGUn4crx8Y", label: "Discord Community" },
      { href: "https://www.bitfari.org/community/", label: "Community Resources" },
      { href: "https://bitcointalk.org/index.php?topic=5344556.0", label: "Bitfari on BitcoinTalk.org" },
    ],
  },
];

const Footer: React.FC = () => {
  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      <div key={index} className="text-sm">
        <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
          {menu.title}
        </h2>
        <ul className="mt-5 space-y-4">
          {menu.menus.map((item, index) => (
            <li key={index}>
              <a
                key={index}
                className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                href={item.href}
              >
                {item.label}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="nc-Footer relative py-20 lg:pt-32 lg:pb-28 border-t border-neutral-200 dark:border-neutral-700">
      <div className="container grid grid-cols-2 gap-y-10 gap-x-5 sm:gap-x-8 md:grid-cols-4 lg:grid-cols-5 lg:gap-x-10 ">
        <div className="grid grid-cols-4 gap-5 col-span-2 md:col-span-4 lg:md:col-span-1 lg:flex lg:flex-col">
          <div className="col-span-2 md:col-span-1">
            <Logo />
          </div>
          <div className="col-span-2 flex items-center md:col-span-3">
            <SocialsList1 className="flex items-center space-x-2 lg:space-x-0 lg:flex-col lg:space-y-3 lg:items-start" />
          </div>
        </div>
        {widgetMenus.map(renderWidgetMenuItem)}
      </div>
    </div>
  );
};

export default Footer;
