import React from 'react'
import { Fragment } from "react";
import { authenticate } from '../auth'
import ButtonPrimary from "../shared/Button/ButtonPrimary";

const Signin = () => {
return (
<Fragment>
<div>
<ButtonPrimary onClick={() => authenticate()}>Connect your Stacks Wallet to Enter</ButtonPrimary>
<br/><br/>
</div>
        <div>
          Welcome to Bitfari's Digital Land!
          Virtual Billboards, Digital Land NFTs and More!
  
          The marketplace will open soon!! Digital Land NFT Purchases, premium collections, Club100K integration, and discounts are live!
          It's all finally here!
          <br/>
          </div>

          <div>
           And for the poor souls lacking a Stacks Wallet:<br/>  
            <a style={{color: "gray"}} 
           href="https://www.hiro.so/wallet/install-web" target="_blank" rel="noreferrer">Get a Stacks Wallet Here (It's free and easy to set up!)</a>
           </div>
    </Fragment>
  )
  } 

export default Signin;


